import React, {
    createContext,
    useContext,
    useEffect,
    useRef,
    useState,
  } from "react";
  import { io, Socket } from "socket.io-client";
  import alarmAudio from "../../assets/audios/alarm-clock.mp3";
  import { useAuth } from "../AuthProvider/AuthProvider";
  import { useMessagesPopup } from "../MessagesPopupProvider/MessagesPopupProvider";
  import { AlarmDialog } from "../../components/Dialogs/AlarmDialog";
  import { Box, Typography } from "@mui/material";
  
  interface SocketContextType {
    socket: Socket | null;
  }
  
  const LeadSocketContext = createContext<SocketContextType>({ socket: null });
  
  export const useLeadSocket = () => useContext(LeadSocketContext);
  
  export const LeadSocketProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
  }) => {
    const { user } = useAuth();
    const { showTopSuccessSnackbar } = useMessagesPopup();
    const socketRef = useRef<Socket | null>(null);
    const [isAlarmDialogOpen, setAlarmDialogOpen] = useState(false);
    const [alarmResponse, setAlarmResponse] = useState<{ data: any } | null>(
      null
    );
    const audioRef = useRef<HTMLAudioElement | null>(null);
  
    // Preload the audio when the component mounts
    useEffect(() => {
      audioRef.current = new Audio(alarmAudio);
    }, []);
  
    const backendUrl = process.env.REACT_APP_SERVER_URL;
    const finalUrl = backendUrl?.replace(/\/api$/, "").trim();
  
    useEffect(() => {
      if (user.isLogin && user.tokens?.access && user.role==="SALES_TEAM_LEAD") {
        socketRef.current = io(finalUrl as string, {
          query: { token: user.tokens.access },
        });
  
        socketRef.current.on("newLeadRecord", async (response) => {
          // Only show alarm and dialog if the lead is assigned to current user
          if (response.data.assign_to_id === user.user_id) {
            showTopSuccessSnackbar("A new Lead request detected!");
            setAlarmDialogOpen(true);
            setAlarmResponse(response);
    
            try {
              if (audioRef.current) {
                await audioRef.current.play();
                audioRef.current.loop = true;
              }
            } catch (err) {
              console.error("Failed to play audio:", err);
            }
          }
        });
      }
  
      return () => {
        socketRef.current?.off("newLeadRecord");
      };
    }, [user.user_id, user.tokens?.access, showTopSuccessSnackbar]);
  
    const handleCloseAlarmDialog = () => {
      setAlarmDialogOpen(false);
      if (audioRef.current) {
        audioRef.current.pause(); // Pause the audio when the dialog is closed
        audioRef.current.currentTime = 0; // Reset to the start
      }
    };
  
    return (
      <LeadSocketContext.Provider value={{ socket: socketRef.current }}>
        {children}
        <AlarmDialog
          open={isAlarmDialogOpen}
          content={
            <Box>
              <Typography sx={{ mb: 2, fontSize: "20px", fontWeight: "bold" }}>
                Name: {alarmResponse && alarmResponse.data.name}
              </Typography>
              <Typography sx={{ mb: 2, fontSize: "20px", fontWeight: "bold" }}>
                Contact No: {alarmResponse && alarmResponse.data.contact_number}
              </Typography>
              <Typography sx={{ mb: 2, fontSize: "20px", fontWeight: "bold" }}>
                Form Name: {alarmResponse && alarmResponse.data.form_name}
              </Typography>
            </Box>
          }
          onClose={handleCloseAlarmDialog}
        />
      </LeadSocketContext.Provider>
    );
  };