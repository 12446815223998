import { action } from "typesafe-actions";
import {
  ICallCampaign,
  IRecordCount,
  ISearchQueary,
} from "./CallCampaign.type";
import { IDate } from "../../components/Table/hooks/useDateFilter";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import {
  closeLoaderWithMessage,
  openLoaderWithMessage,
  showMessage,
} from "../messages/messagesActions";
import { IRecordCountProps } from "../../views/CallCampaign/component/RecordCount/RecordCount";

export const FETCH_CALL_CAMPAIGN_LIST_PROGRESS =
  "FETCH_CALL_CAMPAIGN_LIST_PROGRESS";
export const FETCH_CALL_CAMPAIGN_LIST_SUCCESS =
  "FETCH_CALL_CAMPAIGN_LIST_SUCCESS";
export const FETCH_CALL_CAMPAIGN_LIST_FAILED =
  "FETCH_CALL_CAMPAIGN_LIST_FAILED";

export const fetchAllCallCampaignListProgress = () =>
  action(FETCH_CALL_CAMPAIGN_LIST_PROGRESS);

export const fetchAllCallCampaignListSuccess = (list: ICallCampaign[]) =>
  action(FETCH_CALL_CAMPAIGN_LIST_SUCCESS, { list });

export const fetchAllCallCampaignListFailed = () =>
  action(FETCH_CALL_CAMPAIGN_LIST_FAILED);

export const fetchAllCallCampaignListAsync =
  (
    showTopSuccessSnackbar: (message: string) => void,
    showTopErrorSnackbar: (message: string) => void,
    pageNumber: number,
    rowsInPerPage: number,
    campaignType?: string,
    dataType?:string,
    date?: IDate,
    status?: string,
    userRole?: string,
    searchValue?: string,
    search?: string,
    selectManager?: string | number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(openLoaderWithMessage("Fetching your data, please wait..."));
      dispatch(fetchAllCallCampaignListProgress());
      let url = "";
      if (status && status !== "ALL") {
        url = "&status=" + status;
      }
      if (userRole === "SALES_TEAM_LEAD") {
        url += `&user_type=SALES_TEAM_LEAD`;
      }

      if (
        userRole === "SALES_TEAM_LEAD" &&
        selectManager &&
        selectManager !== "-1"
      ) {
        url += `&assign_to_id=${selectManager}`;
      }
      if (dataType !== "-1") {
        url += `&data_type=${dataType}`;
      }

      if (
        selectManager &&
        userRole !== "SALES_TEAM_LEAD" &&
        selectManager !== "-1"
      ) {
        url += `&lead_id=${selectManager}`;
      }

      if (searchValue && searchValue !== "") {
        url += `&call_disposition=${searchValue}`;
      }
      let finalUrl =
        `/get-call-campaign-data?from_date=${date?.fromDate}&to_date=${date?.toDate}&pageNo=${pageNumber}&itemsPerPage=${rowsInPerPage}` +
        url;
      if (campaignType === "Today") {
        finalUrl =
          `/get-call-campaign-data?scheduled_call_date=${date?.fromDate}&pageNo=${pageNumber}&itemsPerPage=${rowsInPerPage}` +
          url;
      }

      const res = await api.get(finalUrl);
      const data: ICallCampaign[] = res.data.result ? res.data.result : [];

      dispatch(fetchAllCallCampaignListSuccess(data));
      showTopSuccessSnackbar("Call campaign list fetched successfully!");
    } catch (error) {
      showTopErrorSnackbar("Failed to fetch call campaign list!");
    }finally{
      dispatch(closeLoaderWithMessage());

    }
  };

export const FETCH_RECORD_COUNTS_PROGRESS = "FETCH_RECORD_COUNTS_PROGRESS";
export const FETCH_RECORD_COUNTS_SUCCESS = "FETCH_RECORD_COUNTS_SUCCESS";
export const FETCH_RECORD_COUNTS_FAILED = "FETCH_RECORD_COUNTS_FAILED";

export const fetchRecordsCountsProgress = () =>
  action(FETCH_RECORD_COUNTS_PROGRESS);
export const fetchRecordsCountsSuccess = (data: IRecordCount[]) =>
  action(FETCH_RECORD_COUNTS_SUCCESS, { data });
export const fetchRecordsCountsFailed = () =>
  action(FETCH_RECORD_COUNTS_FAILED);

export const fetchRecordCountsAsync =
  (quesryParam: ISearchQueary): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchRecordsCountsProgress());
      let url =
        quesryParam.type === "Today"
          ? `/get-call-campaign-data-status?scheduled_call_date=${quesryParam.fromDate}&status=${quesryParam?.CallStatus}&pageNo=${quesryParam.tablePagination?.pageNumber}&itemsPerPage=${quesryParam.tablePagination?.rowsInPerPage}`
          : `/get-call-campaign-data-status?from_date=${quesryParam.fromDate}&to_date=${quesryParam.toDate}&pageNo=${quesryParam.tablePagination?.pageNumber}&itemsPerPage=${quesryParam.tablePagination?.rowsInPerPage}`;

      if (quesryParam.userRole === "SALES_TEAM_LEAD") {
        url += `&user_type=SALES_TEAM_LEAD`;
      }

      if (quesryParam?.CallStatus && quesryParam?.CallStatus !== "ALL") {
        url += `&status=${quesryParam?.CallStatus}`;
      }
      if (quesryParam.dataType !== "-1") {
        url += `&data_type=${quesryParam.dataType}`;
      }
      if (
        quesryParam.userRole === "SALES_TEAM_LEAD" &&
        quesryParam.assignManager &&
        quesryParam.assignManager !== "-1"
      ) {
        url += `&assign_to_id=${quesryParam.assignManager}`;
      }

      if (
        quesryParam.userRole !== "SALES_TEAM_LEAD" &&
        quesryParam.assignManager !== "-1" &&
        quesryParam.assignManager
      ) {
        url += `&lead_id=${quesryParam.assignManager}`;
      }
      if (quesryParam.searchValue !== "" && quesryParam.searchValue) {
        url += `&call_disposition=${quesryParam.searchValue}`;
      }
      const res = await api.get(url);
      const data: IRecordCount[] = res.data.statusCounts;
      dispatch(fetchRecordsCountsSuccess(data || []));
    } catch (err: any) {
      dispatch(fetchRecordsCountsFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };
export const FETCH_TOTAL_RECORDS_PROGRESS = "FETCH_TOTAL_RECORDS_PROGRESS";
export const FETCH_TOTAL_RECORDS_SUCCESS = "FETCH_TOTAL_RECORDS_SUCCESS";
export const FETCH_TOTAL_RECORDS_FAILED = "FETCH_TOTAL_RECORDS_FAILED";

export const fetchTotalRecordProgress = () =>
  action(FETCH_TOTAL_RECORDS_PROGRESS);
export const fetchTotalRecordSuccess = (data: string) =>
  action(FETCH_TOTAL_RECORDS_SUCCESS, { data });
export const fetchTotalRecordFailed = () => action(FETCH_TOTAL_RECORDS_FAILED);

export const fetchTotalRecordAsync =
  (quesryParam: ISearchQueary): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchTotalRecordProgress());
      let url =
        quesryParam.type === "Today"
          ? `/get-call-campaign-data-total?scheduled_call_date=${quesryParam.fromDate}&status=${quesryParam.CallStatus}&pageNo=${quesryParam.tablePagination?.pageNumber}&itemsPerPage=${quesryParam.tablePagination?.rowsInPerPage}`
          : `/get-call-campaign-data-total?from_date=${quesryParam.fromDate}&to_date=${quesryParam.toDate}&pageNo=${quesryParam.tablePagination?.pageNumber}&itemsPerPage=${quesryParam.tablePagination?.rowsInPerPage}`;

      if (quesryParam.userRole === "SALES_TEAM_LEAD") {
        url += `&user_type=SALES_TEAM_LEAD`;
      }

      if (quesryParam.CallStatus && quesryParam.CallStatus !== "ALL") {
        url += `&status=${quesryParam.CallStatus}`;
      }
      if (quesryParam.dataType !== "-1") {
        url += `&data_type=${quesryParam.dataType}`;
      }
      if (
        quesryParam.userRole === "SALES_TEAM_LEAD" &&
        quesryParam.assignManager &&
        quesryParam.assignManager !== "-1"
      ) {
        url += `&assign_to_id=${quesryParam.assignManager}`;
      }

      if (
        quesryParam.userRole !== "SALES_TEAM_LEAD" &&
        quesryParam.assignManager !== "-1" &&
        quesryParam.assignManager
      ) {
        url += `&lead_id=${quesryParam.assignManager}`;
      }
      if (quesryParam.searchValue !== "" && quesryParam.searchValue) {
        url += `&call_disposition=${quesryParam.searchValue}`;
      }
      const res = await api.get(url);
      const data = res.data.totalRecords;
      dispatch(fetchTotalRecordSuccess(data || []));
    } catch (err: any) {
      dispatch(fetchTotalRecordFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };



export const CLEAR_ORDER = "CLEAR_ORDER";
export const CLEAR_ORDER_STATE = "CLEAR_ORDER_STATE";
export const clearOrder = () => action(CLEAR_ORDER);
export const clearOrderState = () => action(CLEAR_ORDER_STATE);
